.adv-2-swiper-button-prev{
    // background-color: red !important;
    background-color: #5c6366d8 !important;
    color: #fff !important;
    
}


.adv-slider-services{

    .adv-2-swiper-button-prev{
        width: 4rem;
        height: 4rem;
        .btn-before-dot::before{
            font-size: 1.5rem !important;
            position: absolute;
            content: '<';
            top:16px;
            left: 0.579rem;
        }
        
        .btn-before-dot {
            position: relative !important;
        }
    
    }

    .adv-2-swiper-button-next{
        width: 4rem;
        height: 4rem;
        padding-left: 10px !important;
        .btn-after-dot {
            position: relative !important;
        }
        
        
        .btn-after-dot::after{
            content: '>';
            font-size: 1.5rem !important;
            position: absolute;
            top:16px;
        
        }
    }



    
}



.adv-2-swiper-button-prev:hover{
    background-color: rgb(0 0 0 / 58%) !important;
}

.adv-2-swiper-button-next{
    background-color: #5c6366d8 !important;
    color: #fff !important;
}

.adv-2-swiper-button-next:hover{
    background-color: rgb(0 0 0 / 58%) !important;
}